<template>
  <div class="messages">
    <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
      <div class="list">
        <messages-list :listMessage="listMessage"></messages-list>
      </div>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import { List } from "vant";
Vue.use(List);

import MessagesList from "./components/messagesList";
import Placeholder from "@/components/common/Placeholder";

export default {
  name: "Messages",
  components: {
    MessagesList,
    Placeholder
  },
  data() {
    return {
      userId: "",
      listMessage: [],
      type: "C",
      pageIndex: 1,
      loading: true,
      finished: false,
      isPlaceholder: false
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    }
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.getMessagesByPage();
  },
  methods: {
    getMessagesByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      param = {
        PageIndex: 1,
        userId: this.userId,
        ReceipType: this.type
      };
      this.$api.system
        .messageList(param)
        .then(res => {
          this.loading = false;
          const data = res.data;
          this.listMessage = data;
          if (data === "" || data === null || data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    onLoad() {
      let param = {};
      param = {
        PageIndex: this.pageIndex + 1,
        userId: this.userId,
        ReceipType: this.type
      };
      this.$api.system
        .messageList(param)
        .then(res => {
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.listMessage = this.listMessage.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.messages {
  width: 100%;
  font-size: 26px;
  .list {
    width: 100%;
  }
  .tabList {
    width: 100%;
    padding-top: 38px;
    text-align: center;
    background-color: #ffffff;
    .tabItem {
      width: 33.33%;
      text-align: center;
      font-size: 26px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemTopAction {
        color: #43cd9a;
      }
      .itemBorder {
        height: 8px;
        width: 60px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 16px;
        margin-top: 20px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #43cd9a;
        opacity: 1;
      }
    }
  }
}
</style>